$env: 'production';
@import "variables";
@import '../audi-ui-components/styles/main';
@import 'aui-overrides';
@import 'datepicker';

@import 'bootstrap';

/************************************
* general styles
************************************/
html {
  scroll-behavior: smooth;
}

html, body, #AppContainer {
  height: 100%;
  background: $aui-color-gray95;
}

hr {
  color: $aui-color-gray80;
  border-style: solid;
  border-width: 1px 0 0;
  margin: 0;
}

a {
  outline: none;
}

i, em {
  font-style: italic;
}
b, strong {
  font-weight: $aui-font-weight-bold;
}

.font-default {
  font-family: $aui-font-default;
}
.font-extended {
  font-family: $aui-font-extended;
}

.vehicle-img {
  height: 0;
  padding-top: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.plain-button {
  border: none;
  padding: 0;
  box-shadow: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  &.active {
    color: $aui-color-red;
  }
}

.reserved-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  padding: 0;
  border-radius: 50%;
  background: $aui-color-yellow;
}
.reserved-text {
  color: darken($aui-color-yellow, 8%);
}

.account-obfuscated {
  &::before {
    content: '\2219 \2219 \2219 \2219';
    font-family: Arial;
  }
}

.detail-close .svg-wrapper {
  position: relative;
  display: inline-block;
  width: 30px; height: 30px;
  svg {
    position: absolute;
    top: -8px;
    left: -8px;
  }
}

.tabs {
    flex-wrap: nowrap;
    padding-top: 1px;
    .col {
        overflow: hidden;
    }
    .tab {
        opacity: .3;
        border-bottom: 1px solid;
        width: 100%;
        text-align: left;
        &.active {
            opacity: 1;
            font-weight: $aui-font-weight-bold
        }
    }
}


/************************************
* header
************************************/
@import 'header';


/************************************
* footer
************************************/
footer {
}

/************************************
* loading spinner
************************************/
@import 'spinner';

/************************************
* tooltip
************************************/
@import 'tooltip';

/************************************
* login page
************************************/
@import 'login';

/************************************
* list page
************************************/
@import 'list-page';

/************************************
* entry detail page
************************************/
.page-entry {
}
.rb-estimate {
  .rb-estimate-arrow {
    width: 100px;
    height: 1px;
    border-top: 1px solid;
    position: relative;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    &::before, &::after {
      content: " ";
      width: 9px;
      height: 9px;
      border-style: solid;
      border-width: 1px 1px 0 0;
      display: block;
      position: absolute;
      top: -5px;
    }
    &::before {
      left: 1px;
      transform: rotate(225deg);
    }
    &::after {
      right: 1px;
      transform: rotate(45deg);
    }
  }
}

/************************************
* group page
************************************/
.page-group {
  .aui-fieldset__field {
    width: calc(100% - 1.5rem);
    @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
      width: calc(50% - 1.5rem);
    }
    @media (min-width: map-get($aui-breakpoint, 'small')) {
      width: calc(33% - 1.5rem);
    }
  }
  .aui-fieldset__legend {
    display: block;
    margin: 0 0 18px;
  }
}

/************************************
* payouts
************************************/
.page-payouts {
  .payout-status {
    display: inline-block;
    background: $aui-color-gray85;
    color: $aui-color-light;
    border-radius: 15px;
    padding: 1px 8px 2px;
    font-size: 10px;
    line-height: 16px;
    vertical-align: text-top;
    &.paid {
      background: $aui-color-green;
    }
    &.failed {
      background: $aui-color-red;
    }
    &.canceled {
      background: $aui-color-black;
    }
  }
}

.page-payout {
  .payout-detail__row {
    border-top: 1px solid $aui-color-gray80;
    &:first-child {
      border-top: none;
    }
  }
}

/************************************
* support
************************************/
iframe.mojo {
  width: 100%;
  max-width: 680px;
  border: none;
  min-height: 780px;
  @media (min-width: map-get($aui-breakpoint, 'xsmall')) {
    min-height: 720px;
  }
}
