$aui-rem-base: 16px !default; // in px
$aui-base-unit: 4px !default; // in px

$aui-layout-min-width: 288px !default; // = smallest grid width on 320px viewport.

$aui-pi: 3.14159;

/**
 * Modular Scale
 */
$aui-modular-scale: ( default: ( base: 16px, ratio: 1.14, ), small: ( ratio: 1.17, ), large: ( base: 18px, ratio: 1.21, ), huge: ( base: 20px, ratio: 1.25, ), ) !default;

/**
 * Animation & Transitions
 */
$aui-easing: cubic-bezier(0.75, 0.02, 0.5, 1) !default;

/**
 * Colors
 */
$aui-color-red: #bb0a30 !default;
$aui-color-yellow: #ffaa00 !default;
$aui-color-green: #009900 !default;
$aui-color-silver: #b2b2b2 !default;
$aui-color-warmsilver: #b6b1a9 !default;
$aui-color-black: #000000 !default;
$aui-color-gray10: #1a1a1a !default;
$aui-color-gray20: #333333 !default;
$aui-color-gray30: #4c4c4c !default;
$aui-color-gray40: #666666 !default;
$aui-color-gray50: #808080 !default;
$aui-color-gray60: #999999 !default;
$aui-color-gray70: #b2b2b2 !default;
$aui-color-gray80: #cccccc !default;
$aui-color-gray85: #d9d9d9 !default;
$aui-color-gray90: #e5e5e5 !default;
$aui-color-gray95: #f2f2f2 !default;
$aui-color-white: #ffffff !default;

$aui-color-light: $aui-color-gray95 !default;
$aui-color-dark: $aui-color-gray20 !default;
$aui-color-body: $aui-color-dark !default;
$aui-color-placeholder: $aui-color-gray60 !default;
$aui-color-invalid: $aui-color-red !default;

/**
 * Font
 */
$aui-font-default: #{"\'AudiTypeScreen\'", Verdana, Geneva, sans-serif} !default;
$aui-font-extended: #{"\'AudiTypeExtended\'", Verdana, Geneva, sans-serif} !default;
$aui-font-weight-light: 300 !default;
$aui-font-weight-normal: 400 !default;
$aui-font-weight-bold: 700 !default;

// To smooth circles in Chrome on subpixel, we define an scaling factor:
$aui-circle-smoothing-factor: 2 !default;

/**
 * Icons
 */
$aui-icon-size-small: 24px !default;
$aui-icon-size-large: 48px !default;
$aui-icon-system-size-small: 23px !default;
$aui-icon-system-size-large: 45px !default;
$aui-icon-stroke-width: 1.1px !default;

/**
 * SVG
 */
$aui-svg-arrow-width: 8px !default;
$aui-svg-arrow-height: 13px !default;

/**
 * Breakpoints
 */
$aui-breakpoint: ( tiny : 480px, xsmall : 600px, small : 740px, medium : 960px, large : 1264px, xlarge : 1424px, huge : 1584px, ) !default;


// $aui-shadow: 0 10px 50px rgba(#000, .1);

/**
 * Card Component
 */
$aui-card-spacing: unit(1) !default;

/**
 * Form Field Components, like Textfield
 */
// $aui-field-floating-label-padding: unit(4);
$aui-field-floating-label-padding: 0.6875rem !default;
$aui-field-floating-label-font-size: .75rem !default;
$aui-field-default-height: unit(10) !default;
$aui-field-line-height: 1.5 !default;
$aui-field-error-line-height: unit(5) !default;
$aui-field-error-color: $aui-color-invalid !default;
$aui-field-disabled-opacity: 0.3 !default;
$aui-field-placeholder-color: rgba(#000, 0.6) !default;
$aui-field-placeholder-color-light: rgba(#fff, 0.6) !default;
$aui-field-transition-duration: 0.25s !default;
$aui-field-label-y: 0.5rem !default;
$aui-field-focus-line-height: 1px !default;

/**
 * Grid Component
 */
$aui-grid-columns: 12 !default;
$aui-grid-gutters: unit(1) !default;
$aui-grid-container-offset: 4.375% !default; // in percent

/**
 * Visibility Component
 */
$aui-visibility: ( tiny, xsmall, small, medium, large, xlarge, huge, );

/**
 * z-index
 */
$aui-zindex-header: 100 !default;
$aui-dropdown-open-index: 1000 !default;
$aui-alert-zindex: 1700 !default;
$aui-notification-zindex: 1701 !default;
$aui-button-floating-zindex: 900 !default;
$aui-zindex-modal-backdrop: 1800 !default;
$aui-zindex-modal-morph: 1801 !default;
$aui-zindex-modal: 1900 !default;
$aui-zindex-dropdown: 1000 !default;
$aui-zindex-nav-dropdown-toggle: 1001 !default;
$aui-zindex-tooltip: 1002 !default;
$aui-zindex-popover: 1002 !default;
$aui-zindex-dropdown: 1002 !default;
$aui-zindex-nav-sticky: 1001 !default;

%aui-hidden-input {
  appearance: none;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0;
  z-index: -1;
}
