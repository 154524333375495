.main-header {
  position: relative;
  position: sticky;
  top: 0;
  z-index: 100;
  &.open {
    z-index: 101;
  }

  &>.container {
    position: relative;
  }

  .home-link {
    vertical-align: middle;
    display: block;
    padding-top: 5px;
    position: relative;
  }

  .search-site-col {
    max-width: calc(100% - 215px);
    @media (max-width: map-get($aui-breakpoint, 'xsmall') - 1) {
      padding: 0;
    }
  }
  .search-site-wrapper {
    margin-top: -4px;
  }
  .search-site {
    z-index: 20;
    padding-top: 0;
    padding-bottom: 20px;
    .aui-textfield__description {
      position: absolute;
      margin-top: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}


.main-nav__toggle {
  z-index: 20;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28px;
  padding: 8px 0;
  height: 36px;
  color: currentColor;
  cursor: pointer;
  span {
    display: block;
    transition: opacity 0.2s ease, transform 0.3s ease;
    opacity: 1;
    background-color: currentColor;
    height: 1px;
  }
  @include media-breakpoint-up(small) {
    width: 32px;
    padding: 10px 0;
    height: 40px;
  }
  &.open {
    span:first-child {
      transform: translateY(10px) rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: translateY(-9px) rotate(-45deg);
    }
  }
}

.main-nav-wrapper {
  z-index: -1;
  width: 100%;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &.open {
    height: 100vh;
  }

  @include media-breakpoint-up(small) {
    z-index: 1;
  }
}

.main-nav__overlay {
  z-index: 8;
  background: $aui-color-black;
  opacity: 0;
  transition: opacity 0.2s;
  &.open {
    opacity: 0.5;
    width: 100%;
    height: 100vh;
  }
}

.main-nav__panel {
  z-index: 10;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
  background: $aui-color-white;
  font-weight: $aui-font-weight-normal;
  transform: translate(-100%, -100%);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.4s, opacity 0.2s;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;

  @include media-breakpoint-up(small) {
    width: 50%;
    max-width: 500px;
    height: auto;

    > * {
      min-width: 300px;
    }
  }

  &.open {
    transform: translate(0, 0);
    opacity: 1;
  }

  > * {
    padding: 130px 30px 50px 107px;
    @include media-breakpoint-up(xsmall) {
      padding-top: 100px;
    }
  }

  ul {
    list-style: none;
    .divider {
      border-top: 1px solid;
      max-width: 300px;
      margin: 15px 0;
    }
    a {
      cursor: pointer;
      display: inline-block;
      padding: 10px 0;
      transition: color 0.3s linear;
      &:hover {
        color: $aui-color-black;
      }
      &.active {
        color: $aui-color-red;
      }
    }
    .audiicon {
      vertical-align: text-bottom;
      margin-right: 10px;
    }
  }

}
